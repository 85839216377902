div.dataTables_wrapper {
	margin-bottom: 1.25em;
}

div.dataTables_length label,
div.dataTables_filter label,
div.dataTables_info {
	color: #999;
	font-weight: normal;
}

div.dataTables_length label {
	float: left;
	text-align: left;
	margin-bottom: 0;
}

div.dataTables_length select {
	width: 75px;
	margin-bottom: 0;
}

div.dataTables_filter label {
	float: right;
	margin-bottom: 0;
}
div.dataTables_filter input {
	display: inline-block !important;
	width: auto !important;
	margin-bottom: 0;
	margin-left: 0.5em;
}

div.dataTables_info {
	padding-top: 2px;
	font-size: 0.875em;
}

div.dataTables_paginate {
	float: right;
	margin: 0;
}

table.dataTable {
	clear: both;
	margin: 0.5em 0 !important;
	max-width: none !important;
	width: 100%;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
	cursor: pointer;
	*cursor: hand;
}

table.dataTable thead th {
    position: relative;
    background-image: none !important;
}
 
table.dataTable thead th.sorting:after,
table.dataTable thead th.sorting_asc:after,
table.dataTable thead th.sorting_desc:after {
    position: absolute;
    top: 12px;
    right: 8px;
    display: block;
    font-family: FontAwesome;
}

table.dataTable thead th.sorting:after {
    content: "\f0dc";
    color: #ddd;
    font-size: 0.8em;
    padding-top: 0.12em;
}
table.dataTable thead th.sorting_asc:after {
    content: "\f0de";
}
table.dataTable thead th.sorting_desc:after {
    content: "\f0dd";
}

table.dataTable th:active {
	outline: none;
}

/* Scrolling */
div.dataTables_scrollHead table {
	margin-bottom: 0 !important;
}

div.dataTables_scrollBody table {
	border-top: none;
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

div.dataTables_scrollBody tbody tr:first-child th,
div.dataTables_scrollBody tbody tr:first-child td {
	border-top: none;
}

div.dataTables_scrollFoot table {
	margin-top: 0 !important;
	border-top: none;
}




/*
 * TableTools styles
 */
.table tbody tr.active td,
.table tbody tr.active th {
	background-color: #08C;
	color: white;
}

.table tbody tr.active:hover td,
.table tbody tr.active:hover th {
	background-color: #0075b0 !important;
}

.table-striped tbody tr.active:nth-child(odd) td,
.table-striped tbody tr.active:nth-child(odd) th {
	background-color: #017ebc;
}

table.DTTT_selectable tbody tr {
	cursor: pointer;
	*cursor: hand;
}

div.DTTT {
	float: left;
	margin-bottom: 0;
	margin-top: -0.3em;
}

div.DTTT .button {
	margin: 0 0 0.25rem;
}

div.DTTT .button:hover {
	text-decoration: none !important;
}

ul.DTTT_dropdown.dropdown-menu li {
	position: relative;
}

ul.DTTT_dropdown.dropdown-menu li:hover a {
	background-color: #0088cc;
	color: white !important;
}

/* TableTools information display */
.DTTT_print_info {
	position: fixed;
	top: 50%;
	left: 50%;
	width: 400px;
	height: 150px;
	margin-left: -200px;
	margin-top: -75px;
	text-align: center;
	color: #333;
	padding: 10px 30px;

	background: #ffffff; /* Old browsers */
	background: -webkit-linear-gradient(top, #ffffff 0%,#f3f3f3 89%,#f9f9f9 100%); /* Chrome10+,Safari5.1+ */
	background:    -moz-linear-gradient(top, #ffffff 0%,#f3f3f3 89%,#f9f9f9 100%); /* FF3.6+ */
	background:     -ms-linear-gradient(top, #ffffff 0%,#f3f3f3 89%,#f9f9f9 100%); /* IE10+ */
	background:      -o-linear-gradient(top, #ffffff 0%,#f3f3f3 89%,#f9f9f9 100%); /* Opera 11.10+ */
	background:         linear-gradient(top, #ffffff 0%,#f3f3f3 89%,#f9f9f9 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f9f9f9',GradientType=0 ); /* IE6-9 */
	
	opacity: 0.95;

	border: 1px solid black;
	border: 1px solid rgba(0, 0, 0, 0.5);
	
	-webkit-border-radius: 6px;
	   -moz-border-radius: 6px;
	    -ms-border-radius: 6px;
	     -o-border-radius: 6px;
	        border-radius: 6px;
	
	-webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
	   -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
	    -ms-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
	     -o-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
	        box-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
}

div.DTTT_print_info h6 {
	font-weight: normal;
	font-size: 28px;
	line-height: 28px;
	margin: 1em;
}

div.DTTT_print_info p {
	font-size: 14px;
	line-height: 20px;
}



/*
 * FixedColumns styles
 */
div.DTFC_LeftHeadWrapper table,
div.DTFC_LeftFootWrapper table,
table.DTFC_Cloned tr.even {
	background-color: white;
}

div.DTFC_LeftHeadWrapper table {
	margin-bottom: 0 !important;
}

div.DTFC_LeftBodyWrapper table {
	border-top: none;
	margin-bottom: 0 !important;
}

div.DTFC_LeftBodyWrapper tbody tr:first-child th,
div.DTFC_LeftBodyWrapper tbody tr:first-child td {
	border-top: none;
}

div.DTFC_LeftFootWrapper table {
	border-top: none;
}


