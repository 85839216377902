@import "foundation/functions";

// $include-open-sans: false !default;
// $rem-base: 12px;
$row-width: rem-calc(1200);
// 

$white       : #FFFFFF;
$ghost       : #FAFAFA;
$snow        : #F9F9F9;
$vapor       : #F6F6F6;
$white-smoke : #F5F5F5;
$silver      : #EFEFEF;
$smoke       : #EEEEEE;
$gainsboro   : #DDDDDD;
$iron        : #CCCCCC;
$base        : #AAAAAA;
$aluminum    : #999999;
$jumbo       : #888888;
$monsoon     : #777777;
$steel       : #666666;
$charcoal    : #555555;
$tuatara     : #444444;
$oil         : #333333;
$jet         : #222222;
$black       : #000000;
$primary-color: #2995D0;

$base-font-size: 13px;

$button-med: rem-calc(10);

// $primary-color: $oil;

$alert-font-size: rem-calc(12);
$alert-close-position: rem-calc(5);

/* === TOPBAR PARAMETERS === */
$topbar-bg-color: #2995D0;
$topbar-primary-color: $ghost;

$topbar-bg: $topbar-bg-color;
$topbar-link-bg: $topbar-bg;
$topbar-link-bg: $topbar-bg;
$topbar-link-bg-hover: scale-color($topbar-bg, $lightness: -14%);
$topbar-link-bg-color-hover: $topbar-bg;
$topbar-link-bg-active: scale-color($topbar-bg, $lightness: 10%);
$topbar-link-bg-active-hover: scale-color($topbar-bg, $lightness: -14%);
$topbar-title-font-size: rem-calc(13);
$topbar-link-font-size: rem-calc(12);
$topbar-link-font-family: 'Ubuntu', sans-serif;
$topbar-link-text-transform: normal;
$topbar-link-color: $topbar-primary-color;
$topbar-height: rem-calc(37);
$topbar-dropdown-label-color: $smoke;
$topbar-dropdown-bg: $topbar-bg;
$topbar-dropdown-link-bg: $topbar-bg;
$topbar-dropdown-link-bg-hover: scale-color($topbar-bg, $lightness: -14%);
$topbar-dropdown-bg: $topbar-bg-color;
/* === End TOPBAR PARAMETERS === */

$crumb-padding: rem-calc(9 14 9);
$crumb-side-padding: rem-calc(8);
$crumb-font-size: rem-calc(12);
$crumb-font-color: $vapor;
$crumb-font-color-current: $white;
$crumb-font-transform: none;
$crumb-slash: ">";
$crumb-slash-color: $vapor;
$crumb-border-size: 0px;
$crumb-bg: scale-color(#E51320, $lightness: 10%);

$table-head-padding: rem-calc(5 5 7);
$table-head-font-size: rem-calc(12);
$table-head-font-family: 'Economica', sans-serif;
$table-head-font-weight: normal;
$table-head-padding: rem-calc(0 0 0) !default;
$table-head-bg: $steel !default;
$table-head-font-color: $white;

$table-display: table-cell;
$table-margin-bottom: rem-calc(40);
$table-row-font-size: rem-calc(11);
$table-row-padding: rem-calc(5 5 2);
$table-even-row-bg: $white;
$table-border-style: solid;
$table-border-size: 1px;
$table-border-color: $steel;

$button-font-med: rem-calc(12);
$button-font-tny: rem-calc(7);
$button-font-sml: rem-calc(10);
$button-font-lrg: rem-calc(17);

$button-med: rem-calc(10);
$button-tny: rem-calc(7);
$button-sml: rem-calc(8);
$button-lrg: rem-calc(16);

$tabs-navigation-font-family: 'Ubuntu', sans-serif;
$tabs-navigation-font-size: rem-calc(12);
$tabs-navigation-active-bg-color: $snow;
$tabs-navigation-bg-color: $silver;

$form-label-font-size: rem-calc(12);

$pagination-li-font-size: rem-calc(12);
$pagination-li-margin: rem-calc(1);
$pagination-link-current-background: $steel;

$form-label-small-transform: uppercase;
$input-font-size: rem-calc(12);

$tooltip-font-size: rem-calc(12);

