@charset "UTF-8";

@import "settings";

@import "extras/reset";
@import "extras/font-awesome";
@import "extras/jstree";
@import "extras/jquery.datetimepicker";
@import "extras/dataTables.foundation";
@import "extras/dataTables.fontAwesome";
@import "extras/colpick";
@import "extras/leaflet";
@import "extras/foundation.min";
@import "extras/jquery-ui";

@import "foundation";

body { background-color: #fff; height:100%; }
// * { font-family: 'Open Sans', sans-serif; }
a { color: inherit; }
a:hover { color: inherit; text-decoration: none; }

h1,h2,h3,h4,h5,h6 { font-family: 'Ubuntu', sans-serif; }
h2 { font-size: 1.5em; }
h3 { font-size: 1.25em; }

.wizard button { padding: 10px 15px; }

.nav-title div { font-family: 'Lato', sans-serif; }

// #login-section { background: url('/img/dashboard.png') no-repeat center center fixed; height:100%;
//     background-size: contain; -webkit-background-size: contain; background-repeat: no-repeat;
//     -moz-background-size: contain; -o-background-size: contain; background-position: center;
//     background-size: contain; }

#login { padding-top: 100px; background-color: inherit; }

.login-title { font-family: 'Lato', sans-serif; font-weight: normal; font-size: 2.25em; color: #e51320; }

.breadcrumbs-container { margin-top: 20px; }

.page-title { font-family: 'Ubuntu', sans-serif; font-size: 1.75em; border-bottom: 1px solid $iron; margin-bottom: 20px; }

.modal-title { font-family: 'Ubuntu', sans-serif; font-size: 1.75em; }

.modal-subtitle { font-family: 'Ubuntu', sans-serif; font-size: 1.75em; }

// We use these to control various hover effects.
$link-function-factor: -20% !default;
$link-color: $charcoal;
$link-hover: scale-color($link-color, $lightness: $link-function-factor) !default;
$link-success-color: $success-color;
$link-success-hover: scale-color($link-success-color, $lightness: $link-function-factor) !default;
$link-secondary-color: $iron;
$link-secondary-hover: scale-color($link-secondary-color, $lightness: $link-function-factor) !default;
$link-warning-color: $warning-color;
$link-warning-hover: scale-color($link-warning-color, $lightness: $link-function-factor) !default;
$link-alert-color: $alert-color;
$link-alert-hover: scale-color($link-alert-color, $lightness: $link-function-factor) !default;
$link-info-hover: scale-color($info-color, $lightness: $link-function-factor) !default;
.link {
	color: $link-color; &:hover { color: $link-hover; }
	&.success  { color: $success-color; &:hover { color: $link-success-hover }}
    &.info { color: $info-color; &:hover { color: $link-info-hover }}
	&.secondary  { color: $link-secondary-color; &:hover { color: $link-secondary-hover }}
	&.warning  { color: $link-warning-color; &:hover { color: $link-warning-hover }}
	&.alert  { color: $link-alert-color; &:hover { color: $link-alert-hover; }}
}

.alert-container { width:100%; position: absolute; z-index: 1; right: 0; }

#settings-system {
	.label-button { @include button($button-med, false, $global-radius, true, false, false);

	margin-top: rem-calc(16); }
}

.fa {
	&.success { color: $success-color; }

	&.alert { color: $alert-color; }
}

#map-canvas {  height: 480px; }

// .gmnoprint img { max-width: none; }

.controls {
    margin-top: 16px;
    border: 1px solid transparent;
    border-radius: 2px 0 0 2px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    height: 32px;
    outline: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  }

  #pac-input {
    background-color: #fff;
    // font-family: Roboto;
    // font-size: 15px;
    font-weight: 300;
    margin-left: 12px;
    padding: 0 11px 0 13px;
    text-overflow: ellipsis;
    width: 50%;
  }

  #pac-input:focus {
    border-color: #4d90fe;
  }

.v-scrollable { overflow-y: auto; }

.data-block { margin-bottom: 2em; }

.inline-input button { 
    @include button(
    // $padding - Used to build padding for buttons Default: $button-med or rem-calc(12)
    rem-calc(10),
    // Background color. We can set $bg:false for a transparent background. Default: $primary-color.
    $primary-color,
    // If true, set to button radius which is $global-radius or explicitly set radius amount in px (ex. $radius:10px). Default:false.
    false,
    // We can set $full-width:true to remove side padding extend width. Default:false
    false,
    // We can set $disabled:true to create a disabled transparent button. Default:false
    false,
    // $is-input - <input>s and <button>s take on strange padding. We added this to help fix that. Default:false
    true
  );

    margin-top: 1.35em;
}

.label {
    &.expand { width: 100%; }
}

.title-area .name a { padding-left: 0px !important; }

table.owl-datatable tbody th { font-weight: bold; }

.case-status {
    padding: 10px 10px;

    &.alert   { background-color: $alert-color; }
    &.info    { background-color: $info-color; }
    &.success { background-color: $success-color; }
    &.warning { background-color: $warning-color; }
    &.alert   { background-color: $alert-color; }
}

.color-box { float:left; width:30px; height:30px; margin:5px; border: 1px solid white; }

table.dataTable thead .sorting { background: url('images/sort_both.png') no-repeat center right; }
table.dataTable thead .sorting_asc { background: url('images/sort_asc.png') no-repeat center right; }
table.dataTable thead .sorting_desc { background: url('images/sort_desc.png') no-repeat center right; }

table.dataTable thead .sorting_asc_disabled { background: url('images/sort_asc_disabled.png') no-repeat center right; }
table.dataTable thead .sorting_desc_disabled { background: url('images/sort_desc_disabled.png') no-repeat center right; }

table.dataTable.hover tbody tr:hover, table.dataTable.display tbody tr:hover {
  background-color: $gainsboro;
}
table.dataTable.hover tbody tr:hover.selected, table.dataTable.display tbody tr:hover.selected {
  background-color: #a9b7d1;
}

table.dataTable.row-border tbody th, table.dataTable.row-border tbody td, table.dataTable.display tbody th, table.dataTable.display tbody td {
  border-top: 1px solid $gainsboro;
}
table.dataTable.row-border tbody tr:first-child th,
table.dataTable.row-border tbody tr:first-child td, table.dataTable.display tbody tr:first-child th,
table.dataTable.display tbody tr:first-child td {
  border-top: none;
}

table.dataTable.compact thead th,
table.dataTable.compact thead td {
  padding: 5px 17px 4px 4px;
}
table.dataTable.compact tfoot th,
table.dataTable.compact tfoot td {
  padding: 4px;
}
table.dataTable.compact tbody th,
table.dataTable.compact tbody td {
  padding: 4px;
}

table.dataTable tbody tr.selected {
  background-color: #B0BED9;
}

.checkout-table {
  @include table();
}

#scr-table tbody > tr { margin-top:0 !important; padding-top: 0 !important; vertical-align: top !important; }
#scr-table tbody > tr > td { margin-top:0 !important; padding-top: 0 !important; vertical-align: top !important; }
#scr-table .dataTable.compact tbody td { padding: 0px; margin: 0px; line-height: 1; vertical-align: top !important; }

.scheduler-container { padding: 0 !important; margin: 0 !important; }
.scheduler-container     > div         { width: 100%; margin: 0; padding: 0; }
/*.scheduler-container .hour-spot-container { position: absolute; width: 100%; }*/
.scheduler-container .hour-spot  { position: relative; width: 4%; top: 0px; height: 30px; float: left; border-right: 1px solid #ccc;}
.scheduler-container .event-container { position: relative; width: 100%; height: 24px; }
.scheduler-container .event           { color: #fff; margin-top: 2px; padding-top: 3px; height: 90%; background-color: #0068c9; position: absolute; z-index: 1; text-align: center; height: 100%; }
.scheduler-container .event.success   { background-color: $success-color; }
.scheduler-container .event.info      { background-color: $info-color; color: #555; }
.scheduler-container .event.alert     { background-color: $alert-color; }
.scheduler-container .event a         { font-size: 0.917em; }
.scheduler-container .event.success:hover { background-color: scale-color($success-color, $lightness: -14%); }
.scheduler-container .event.info:hover { background-color: scale-color($info-color, $lightness: -14%); }
.scheduler-container .event.alert:hover   { background-color: scale-color($alert-color, $lightness: -14%); }

.truck-progress { background-color: $secondary-color; position: relative; top: 2px; width: 100%; z-index: 100; }

.subtable {
  @include table();
  border: 1px solid #eee; 

  thead { 
    background-color: #eee; 

    tr > td { color: #444; }
  }

}

table thead tr th.grid  { padding: 0 !important; margin: 0 !important;}
table thead tr th.grid div { border-left: 1px solid #ddd; margin:0; padding:0; height: 100%; }
table thead tr th.grid div span { padding:0;margin: 0;}